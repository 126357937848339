import { Controller } from "stimulus";

export default class extends Controller {
  removeFileClick(event): void {
    event.target.classList.add("hidden"); // hide delete button
    const container = event.target.closest(".item");
    container.classList.add("animated", "fadeOut");

    container.addEventListener(
      "animationend",
      function() {
        this.remove();
      },
      false
    );
  }
}
