import { Controller } from "stimulus";

export default class extends Controller {
  connect(): void {
    // https://getbootstrap.com/docs/4.2/content/tables/#responsive-tables
    $(this.element).find("table").wrap("<div class='table-responsive'></div>");

    // append custom captions so table can be styled properly
    $(this.element)
      .find("table[caption]")
      .prepend(function () {
        return `<caption>${$(this).attr("caption")}</caption>`;
      });
  }
}
