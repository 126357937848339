export function notification(
  message,
  notificationType,
  timeout: number | boolean = 9000,
): void {
  const progressBarColors = {
    error: "#ff483d",
    notice: "#0050b2",
    success: "#19be10",
    warning: "#feb40a",
  };
  const backgroundColors = {
    error: "#ffeae8",
    notice: "#f5f6f7",
    success: "#dcf6d6",
    warning: "#fffadf",
  };

  // @ts-ignore
  import("izitoast/dist/css/iziToast.css");
  import(/* webpackChunkName: "izitoast" */ "izitoast").then((module) => {
    module.default["info"]({
      icon: `${notificationType}-notification-icon`,
      message: message,
      position: "topCenter",
      timeout: timeout,
      progressBar: false,
      displayMode: 2,
      transitionIn: "flipInX",
      transitionOut: "flipOutX",
      progressBarColor: progressBarColors[`${notificationType}`],
      backgroundColor: backgroundColors[`${notificationType}`],
      layout: 2,
    });
  });
}

export function serverErrorNotification(): void {
  notification("Serverfehler, bitte versuchen Sie es erneut.", "error");
}

export default { notification, serverErrorNotification };
