import { Controller } from "stimulus";

// Taken from here: https://www.stimulus-components.com/docs/stimulus-content-loader
// data-content-loader-url-value                      URL to fetch the content.
// data-content-loader-refresh-interval-value         Interval in milliseconds to reload content.
// data-content-loader-lazy-loading-value             Fetch content when element is visible.
// data-content-loader-lazy-loading-root-margin-value rootMargin option for Intersection Observer.
// data-content-loader-lazy-loading-threshold-value   threshold option for Intersection Observer.
// data-content-loader-load-scripts-value             Load inline scripts from the content.
export default class extends Controller {
  static values = {
    url: String,
    lazyLoading: Boolean,
    lazyLoadingThreshold: Number,
    lazyLoadingRootMargin: String,
    refreshInterval: Number,
    loadScripts: Boolean,
  };

  connect() {
    if (!this.hasUrlValue) return;

    if (this.lazyLoadingValue) this.lazyLoad();
    else this.load();
  }

  disconnect() {
    super.connect();
    this.stopRefreshing();
  }

  load() {
    this.fetch();

    if (this.hasRefreshIntervalValue) {
      this.startRefreshing();
    }
  }

  lazyLoad() {
    const options = {
      threshold: this.lazyLoadingThresholdValue,
      rootMargin: this.lazyLoadingRootMarginValue,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.load();

          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(this.element);
  }

  fetch() {
    fetch(this.urlValue)
      .then((response) => {
        if (response.redirected) {
          window.location.href = response.url;
        } else {
          return response.text();
        }
      })
      .then((html) => {
        if (html === undefined) return;

        this.element.innerHTML = html;

        if (this.loadScriptsValue) this.loadScripts();
      });
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.fetch();
    }, this.refreshIntervalValue);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  loadScripts() {
    this.element.querySelectorAll("script").forEach((content) => {
      const script = document.createElement("script");
      script.innerHTML = content.innerHTML;

      document.head.appendChild(script).parentNode.removeChild(script);
    });
  }
}
