import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["details"];

  onToggle(event) {
    event.preventDefault();
    this.detailsTarget.classList.toggle("hidden");
  }
}
