import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["faqs", "faqItem", "question", "answer"];

  connect() {
    this.setNameAttributes();
  }

  appendFaqToForm() {
    const newFaq = this.faqItemTargets[this.faqItemTargets.length - 1];

    this.faqsTarget.appendChild(newFaq.cloneNode(true));

    this.setNameAttributes();
    this.clearNewFaqInputs();
  }

  removeFaqFromForm(e) {
    if (this.questionTargets.length === 1) {
      this.questionTargets[0].value = "";
      this.answerTargets[0].value = "";
    } else {
      e.target.closest(".faq-item").remove();
    }

    this.setNameAttributes();
  }

  setNameAttributes() {
    for (let i = 0; i < this.questionTargets.length; i += 1) {
      this.questionTargets[i].setAttribute(
        "name",
        `service_article[faq_content][${i}][question]`,
      );
      this.answerTargets[i].setAttribute(
        "name",
        `service_article[faq_content][${i}][answer]`,
      );
    }
  }

  clearNewFaqInputs() {
    this.questionTargets[this.questionTargets.length - 1].value = "";
    this.answerTargets[this.answerTargets.length - 1].value = "";
  }
}
