import 'lazysizes'

window.lazySizes = require('lazysizes/lazysizes')
require('lazysizes/plugins/print/ls.print')

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.expand = 225
window.lazySizesConfig.expFactor = 1.4
window.lazySizesConfig.hFac = 0.4
window.lazySizesConfig.loadMode = 1
window.lazySizesConfig.loadHidden = false
