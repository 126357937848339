interface ConstructorParams {
  parseErrorResponse?: boolean;
  form?: HTMLFormElement;
}

export class Fetcher {
  parseErrorResponse: boolean;
  form: HTMLFormElement | undefined;

  constructor({
    parseErrorResponse = false,
    form = undefined,
  }: ConstructorParams = {}) {
    this.parseErrorResponse = parseErrorResponse;
    this.form = form;
  }

  private parseJSON = (res: Response): Promise<any> => {
    if (res.status === 204 || res.status === 205) return Promise.resolve();

    return res.json();
  };

  private checkStatus = (res: Response): Response => {
    if (res.ok || this.parseErrorResponse) {
      return res;
    } else {
      throw new Error(res.statusText);
    }
  };

  private csrfToken = (): string | null => {
    // NOTE: rails might generate a hidden input within form itself
    // we should use then that CSRF token instead of meta-tag - especially when
    // using turbo rails
    if (this.form) {
      return this.form
        .querySelector('input[type=hidden][name="authenticity_token"]')
        .getAttribute("value");
    } else {
      const metaTag = document.querySelector("meta[name='csrf-token']");

      return metaTag ? metaTag.getAttribute("content") : null;
    }
  };

  request = (path: string, params: RequestInit): Promise<Response> => {
    params.credentials = "same-origin";
    params.headers = params.headers || {};
    params.headers["X-CSRF-Token"] =
      params.headers["X-CSRF-Token"] || this.csrfToken();
    params.headers["Accept"] = params.headers["Accept"] || "application/json";

    return fetch(path, params).then(this.checkStatus).then(this.parseJSON);
  };
}
