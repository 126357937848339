import { Controller } from "stimulus";

interface ImageVideoItem extends PhotoSwipe.Item {
  id: number;
  source: string;
  video?: boolean;
}

export default class extends Controller {
  static targets = ["picture", "photoswipe"];

  private pictureTargets;

  private photoswipeTarget: HTMLElement

  private player;

  connect(): void {
    // @ts-ignore
    import("plyr/dist/plyr.css");
  }

  onImageClick(event): void {
    event.preventDefault();

    var options = {
      history: false,
      shareEl: false,
      closeOnScroll: false,
      closeOnVerticalDrag: false,
      focus: true,
      index: this.items.findIndex(
        (item) => item.source === event.currentTarget.getAttribute("href"),
      ),
    };

    import(/* webpackChunkName: "photoswipe" */ "photoswipe").then(
      (PhotoSwipe) => {
        import(
          /* webpackChunkName: "photoswipe-ui-default" */ "photoswipe/dist/photoswipe-ui-default"
        ).then((PhotoSwipeUIDefault) => {
          const gallery = new PhotoSwipe.default(
            this.photoswipeTarget,
            PhotoSwipeUIDefault.default,
            this.items,
            options,
          );

          gallery.listen("destroy", () => {
            this.cleanupPlayer();
          });

          gallery.listen("preventDragEvent", (event, isDown, preventObj) => {
            // do not prevent touch events when player is up - this fixes seeking problems
            preventObj.prevent = !this.player;
          });

          gallery.listen("beforeChange", () => {
            const currItem = gallery.currItem as ImageVideoItem;
            this.cleanupPlayer();
            if (currItem.video) {
              import(/* webpackChunkName: "plyr" */ "plyr").then((plyr) => {
                this.player = new plyr.default(`#video-${currItem.id}`, {
                  i18n: JSON.parse(this.photoswipeTarget.dataset.labels),
                  hideControls: false,
                  tooltips: { controls: true, seek: true },
                  volume: 0.8,
                });

                this.player.on("ready", () => {
                  gallery.updateSize(false);
                });
              });
            } else {
              const image = new Image();
              image.src = currItem.src;

              image.onload = () => {
                gallery.currItem.w = image.width;
                gallery.currItem.h = image.height;

                gallery.updateSize(false);
              };
            }
          });

          gallery.init();
        });
      },
    );
  }

  get items(): ImageVideoItem[] {
    return this.pictureTargets.map((item) => {
      const src = item.getAttribute("href");
      const id = parseInt(item.dataset.id);

      // dataset value is always provided here
      if (item.dataset.video === "true") {
        return {
          id: id,
          video: true,
          source: src,
          html:
            `<video id="video-${id}" playsinline controls>` +
            `<source src="${src}" type="video/mp4" />` +
            "</video>",
        };
      } else {
        return {
          id: id,
          src: src,
          source: src,
          title: item.getAttribute("title"),
          w: 0,
          h: 0,
        };
      }
    });
  }

  cleanupPlayer(): void {
    if (this.player) {
      this.player.destroy();
      this.player = undefined;
    }
  }
}
