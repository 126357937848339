// Sentry configuration
import * as Sentry from "@sentry/browser";

// const replay = Sentry.replayIntegration({
//   maskAllText: false,
//   blockAllMedia: false,
//   maskAllInputs: true,
// });

Sentry.init({
  debug: false,
  dsn: process.env.SENTRY_JS_DSN,
  maxBreadcrumbs: 20,
  environment: window.SENTRY_ENV,
  release: window.APP_REVISION,
  ignoreErrors: [/ChunkLoadError/],
  enabled: (window.SENTRY_ENV === "production" || window.SENTRY_ENV === "testing"),
  allowUrls: [
    /https?:\/\/\w+\.ofri\.(ch|it)/
  ],
  // replaysSessionSampleRate: 0,
  // replaysOnErrorSampleRate: 1,
  tracesSampleRate: 0.5,
  // NOTE: the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  profilesSampleRate: 0.1,
  tracePropagationTargets: [
    /https:\/\/www\.ofri\.(ch|it)/,
    /^\//
  ],
  integrations: [
    // replay,
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
});
